import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import TableContainer from '../../Table/GroupsTable';
import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { langs } from '@uiw/codemirror-extensions-langs';
import { EditorView } from '@codemirror/view';

import { CopyOutlined } from '@ant-design/icons';

//import { BsPlusCircleDotted } from 'react-icons/bs';
import { classname } from '@uiw/codemirror-extensions-classname';
import { Col, Container, Row, Label, Modal, ModalBody, ModalHeader, FormFeedback, Button, UncontrolledTooltip, Input } from 'reactstrap';
import { message, Upload } from 'antd';
import { Select } from 'antd';

//Import Breadcrumb
//import Breadcrumbs from '../../../components/Common/Breadcrumb';
//import CustomModal from '../../../reus]ables/CustomModal';
import api from '../../api/index';
//import { Checkbox, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// Formik validation
//import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CSVToArray } from 'utils/utils';
import { CustomModal } from './Modal';

function PushSurvey() {
   const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [service, setService] = useState('');
  const [file, setFile] = useState();
  const [pushed, setPushed] = useState([]);
  const [open, setOpen] = useState(false);
  const [anonOpen, setAnonOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [type, setType] = useState(null)
  const [errorLines, setErrorLines] = useState([]);
  const [StaticLink, setStaticLink] = useState('')
  const [copySuccess, setCopySuccess] = useState('');
  const [errLoading, setErrLoading] = useState({
    checking: false,
    loading: false
  });
  const user = JSON.parse(localStorage.getItem('user'));

  const navigate = useNavigate();
  useEffect(() => {
    
    if (user?.login_counter === 0) {
      navigate('/edit-profile');
    }
  }, []);

  useEffect(() => {
    if (service) {
      getPushed(1, 10, service);
    }
  }, [service]);

  useEffect(() => {
    getServiceList(page, pageSize);
  }, [pageSize]);

  const resetAll = () => {
    setOpen(false);
    setAdmin(null);
    AdminValidation.resetForm();
  };

  const customNextPage = (page) => {
    if (page < totalPage) {
      getPushed(Number(page) + 1, pageSize, service);
    } else {
      message.info('You are already on the last page');
    }
  };

  const customPrevPage = (page) => {
    if (page !== 1) {
      getPushed(Number(page) - 1, pageSize, service);
    }
  };

  const customSetPageSize = (size) => {
    setPageSize(size);
  };
  const handleQuestionChange = (value) => {
    setService(value);
  };
  const getServiceList = async (page = 1, pageSize = 10) => {
    try {
      const data = await api.survey.getAllSurvey(page, pageSize);
      if (data?.status === 200) {
        setServiceList(data?.data?.data?.surveys);

        message.success('List refreshed');
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const updateServiceList = async (body) => {
    try {
      const data = await api.push.editPush(body);
      if (data?.status === 200) {
        getServiceList();
        resetAll();
        message.success('Push updated successfully');
      } else {
        message.error(data?.response?.data?.message);
        //setOpen(false)
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      //setOpen(false)
    }
  };

  const createPush = async (body) => {
    console.log(body)
    try {
      const data = await api.push.createPush(body);
      if (data?.status === 200) {
        await getServiceList();
        resetAll();

        message.success('Admin created successfully');
      }
      return data?.data;
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

   const createStaticPush = async (body) => {
    setStaticLink(null)
    setAnonOpen(false)
     try {
       const data = await api.push.createAnonymousPush(body);
       if (data?.status === 200) {
    
      
         await getServiceList();
         resetAll();

         message.success('Push created successfully');
           setStaticLink(data?.data?.data?.link);
            setAnonOpen(true);
       }
       return data?.data;
     } catch (error) {
       message.error(error?.response?.data?.message);
     }
   };

   const copyToClipboard = async () => {
     try {
       await navigator.clipboard.writeText(StaticLink);
       setCopySuccess('Link Copied !');
       setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
     } catch (err) {
       setCopySuccess('Failed to copy!');
     }
   };

  const getPushed = async (page = 1, pageSize = 10, service_name) => {
    try {
      const data = await api.push.getPushs(page, pageSize, service_name);

      setPushed(data?.data?.data?.push_records);
      setPage(data?.data?.data?.currentPage);
      setTotalPage(data?.data?.data?.totalPages);
    } catch (error) {}
  };

  const deleteAdmin = async (id) => {
    try {
      const data = await api.push.deletePush(id);
      if (data?.status == 200) {
        getServiceList();
      }
    } catch (error) {
      message.error(error.data?.data?.message);
    }
  };
  const freeColumns = [
    {
      Header: 'Service name',
      accessor: 'service_name',
      filterable: true
    },
    {
      Header: 'Pushed To',
      accessor: 'pushed_to',
      filterable: true,
      Cell: (data) => {
        const tagList = data?.value.map((x) => x).join(', ');

        return <span>{tagList}</span>;
      }
    },

    {
      Header: 'Channel',
      accessor: 'channel',
      filterable: true
    },
    {
      Header: 'All status',
      accessor: 'all_status',
      filterable: true,
      Cell: (data) => {
        const tagList = data?.value?.map((x) => x).join(', ');

        return <span>{tagList}</span>;
      }
    },
    {
      Header: 'Created by',
      accessor: 'pushed_by',
      filterable: true
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      filterable: true,
      Cell: (data) => new Date(data?.value).toLocaleString()
    }

    // {
    //   Header: 'Actions',
    //   Cell: (cellProps) => {
    //     return (
    //       <div className="d-flex gap-3">
    //         {
    //           <Link
    //             to="#"
    //             className="text-success"
    //             onClick={() => {
    //               //
    //               const data = cellProps.row.original;
    //               setOpen(true);
    //               setAdmin(data);
    //             }}
    //           >
    //             <EditFilled className="font-size-12" id="edittooltip" />
    //             <UncontrolledTooltip placement="top" target="edittooltip">
    //               Edit
    //             </UncontrolledTooltip>
    //           </Link>
    //         }
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           onClick={() => {
    //             const data = cellProps.row.original;
    //             deleteAdmin(data?.id);
    //           }}
    //         >
    //           <DeleteFilled className="font-size-12" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     );
    //   }
    // }
  ];
  const AdminValidation = useFormik({
    enableReinitialize: true,

    initialValues:{
            service_name: admin?.service_name || [],
            pushed_to: admin?.pushed_to || [],
            pushed_by: user?.email,
            channel: admin?.channel || '',
            introductory_msg: admin?.introductory_msg || ''
          },

    onSubmit: (values) => {
      if (!values.introductory_msg) {
        delete values.introductory_msg;
      }
      if (type==='Static') {
        delete values.channel
        delete values.pushed_to
      }

      console.log(values);
      //   delete values.email
      // }
      //admin ? updateServiceList(values) : createAdmin(values);

      type === 'Identified' ? createPush(values) : createStaticPush(values);
    }
  });

  const classnameExt = classname({
    add: (lineNumber) => {
      if (errorLines.map((err) => err.line).includes(lineNumber)) {
        return 'first-line';
      }
    }
  });

  const theme = EditorView.baseTheme({
    '&dark .first-line': { color: '#f14d41' },
    '&light .first-line': { color: '#f14d41' }
  });
  const myTheme = createTheme({
    dark: 'light',
    settings: {
      background: '#ffffff',
      foreground: '#3F3F46',
      border: '1px solid #bdb2f !important',
      caret: 'red',
      fontFamily: 'Inter',
      selection: '#ffffff',
      selectionMatch: '#ffffff',
      gutterBackground: '#f4f3ff',
      gutterForeground: '#4D4D4C',
      gutterBorder: '#007fbc',
      gutterBorderRadius: '9px',
      gutterActiveForeground: '',
      lineHighlight: '#f4f3ff'
    }
  });

  const FontSizeTheme = EditorView.theme({
    $: {
      fontSize: '9pt'
    }
  });
  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text'
    },
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        let reader = new FileReader();
        reader.onload = (event) => {
          const csvOutput = event.target.result;
          const result = CSVToArray(csvOutput, ',');

          setFile(csvOutput);
          const res = result
            ?.map((v) => {
              return v[0].replace('\r', '');
            })
            .filter((r) => r !== '');
          AdminValidation.setFieldValue('pushed_to', res);
        };

        reader.readAsText(info.file);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const onChange = React.useCallback(
    (value, viewUpdate) => {
      // setFile(value);
      const res = CSVToArray(value)
        .map((v) => {
          return v[0].replace('\r', '');
        })
        .filter((r) => r !== '');

      AdminValidation.setFieldValue('pushed_to', res);
    },
    [file]
  );

  return user ? (
    <div className="page-content">
      <Container fluid>
        {StaticLink && (
          <Modal centered style={{ marginTop: '80px' }} scrollble size="md" isOpen={anonOpen}>
            <ModalHeader
              toggle={() => {
                //resetAll();
                setAnonOpen(false);
              }}
              tag="h4"
            >
             Static Link
            </ModalHeader>
            <ModalBody>
              <div style={{ position: 'relative', padding: '20px', border: '1px solid #ddd', borderRadius: '8px', maxWidth: '480px' }}>
                <p style={{ marginTop: '30px ', wordBreak: 'break-word', fontSize: '1em', lineHeight: '1.4em' }}>
                  {StaticLink}
                </p>

                <button
                  onClick={copyToClipboard}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '6px 10px',
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                    color: '#333',
                    backgroundColor: '#f1f1f1',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease'
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#f1f1f1')}
                >
                  <CopyOutlined size={14} />
                  Copy link
                </button>

                {copySuccess && (
                  <span style={{ marginTop: '10px', display: 'block', color: 'green', fontSize: '1em' }}>{copySuccess}</span>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}
        <Modal centered style={{ marginTop: '80px' }} scrollble size="md" isOpen={open}>
          <ModalHeader
            toggle={() => {
              //resetAll();
              setOpen(false);
            }}
            tag="h4"
          >
            {!admin ? 'Push' : 'Edit'} NPS
          </ModalHeader>
          <ModalBody>
            <div>
              <div>
                {/* <h6 style={{ marginTop: '20px' }}>Agents</h6> */}
                <Row>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>service_name</b>
                      </Label>
                      <Select
                        style={{
                          width: '95%'
                        }}
                        name="service_name"
                        id="service_name"
                        mode="tags"
                        value={AdminValidation.service_name}
                        placeholder="Pick a service to push"
                        onChange={(value) => {
                          AdminValidation.setFieldValue('service_name', value);
                        }}
                        options={serviceList?.map((s) => {
                          return { value: s.service_name, label: s.service_name };
                        })}
                      />
                      {AdminValidation.touched.service_name && AdminValidation.errors.service_name ? (
                        <FormFeedback type="invalid">{AdminValidation.errors.service_name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Row>
                    <Col span={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">
                          <b>Type</b>
                        </Label>
                        <Input
                          type="select"
                          name="type"
                          id="channel"
                          onChange={(e) => {setType(e.target.value)}}
                          placeholder="channel"
                          className="form-control"
                          value={type}
                        >
                          <option value="">Identified or Static</option>
                          {['Identified', 'Static'].map((val) => {
                            return (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            );
                          })}
                        </Input>
                      </div>
                    </Col>
                  </Row>
                  {type == 'Identified' && (
                    <Row>
                      <Col span={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">
                            <b>Channel</b>
                          </Label>
                          <Input
                            type="select"
                            name="channel"
                            id="channel"
                            onChange={AdminValidation.handleChange}
                            placeholder="channel"
                            className="form-control"
                            onBlur={AdminValidation.handleBlur}
                            value={AdminValidation.values.channel}
                          >
                            <option value="">Select sms or email</option>
                            {['sms', 'email', 'whatsapp'].map((val) => {
                              return (
                                <option key={val} value={val}>
                                  {val}
                                </option>
                              );
                            })}
                          </Input>
                          {AdminValidation.touched.created_by && AdminValidation.errors.created_by ? (
                            <FormFeedback type="invalid">{AdminValidation.errors.created_by}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col span={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">
                          <b>Message</b>
                        </Label>
                        <Input
                          type="text"
                          name="introductory_msg"
                          id="mintroductory_msg"
                          onChange={AdminValidation.handleChange}
                          placeholder="Optional: Introductory message"
                          className="form-control"
                          onBlur={AdminValidation.handleBlur}
                          value={AdminValidation.values.introductory_msg}
                        ></Input>
                        {AdminValidation.touched.created_by && AdminValidation.errors.introductory_msg ? (
                          <FormFeedback type="invalid">{AdminValidation.errors.introductory_msg}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  {type === 'Identified' && (
                    <Col span={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <b>Push To</b>
                            <p style={{ fontSize: '12px' }}>Enter Emails/Phone Numbers or Upload a csv file</p>
                          </div>
                        </Label>
                        <div
                          style={{
                            border: '1.5px solid #bdb2fc',
                            borderRadius: '7px',
                            overflow: 'hidden',
                            fontSize: 10
                          }}
                        >
                          <CodeMirror
                            value={file ? file : ''}
                            theme={myTheme}
                            height="220px"
                            onChange={onChange}
                            extensions={[langs.mathematica(), classnameExt, FontSizeTheme, theme]}
                          />
                        </div>
                        <div
                          style={{
                            margin: '1px 0px',

                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <CustomModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />

                          <Button
                            style={{
                              padding: '10px',
                              margin: '10px 0',
                              color: '#7a5af8',
                              alignItems: 'center',
                              justifyContent: 'center ',
                              fontSize: '12px'
                            }}
                            type="text"
                            onClick={() => setIsModalOpen(!isModalOpen)}
                          >
                            Show sample CSV
                          </Button>
                          <Upload showUploadList={false} {...props}>
                            <Button
                              style={{
                                padding: '10px',
                                margin: '10px 0',
                                alignItems: 'center',
                                justifyContent: 'center ',
                                fontSize: '12px',
                                color: '#7a5af8'
                              }}
                              type="text"
                              icon={<UploadOutlined style={{ fontSize: '10px' }} />}
                            >
                              Upload CSV file
                            </Button>
                          </Upload>
                        </div>

                        {AdminValidation.touched.description && AdminValidation.errors.pushed_to ? (
                          <FormFeedback type="invalid">{AdminValidation.errors.pushed_to}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  )}
                </Row>

                <div className="modal-footer">
                  <Button
                    type="button"
                    onClick={() => {
                      AdminValidation.handleSubmit();
                    }}
                    className="btn btn-success "
                    disabled={!type||(!AdminValidation.dirty)}
                  >
                    {admin ? 'Edit' : 'PUSH'}
                  </Button>
                  <button
                    type="button"
                    onClick={() => {
                      resetAll();
                      setOpen(false);
                    }}
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <TableContainer
          columns={freeColumns}
          data={pushed}
          setShowDetailsModal={() => {}}
          isGlobalFilter={true}
          handleQuestionChange={handleQuestionChange}
          free={false}
          isAddUserList={false}
          customSetPageSize={(number) => customSetPageSize(number)}
          isPushList={true}
          isAdmin={true}
          handleAddWhiteList={() => {
            setOpen(true);
          }}
          controlledPageIndex={page}
          customPageCount={totalPage}
          customNextPage={() => customNextPage(page)}
          customCanNextPage={page < totalPage}
          customCanPrevPage={page !== 1}
          customPrevPage={() => customPrevPage(page)}
          handleUserClick={() => {}}
          optionList={serviceList?.map((s) => {
            return { value: s.service_name, label: s.service_name };
          })}
          handleWhiteList={() => {}}
          customPageSize={pageSize}
          className="custom-header-css"
        />
      </Container>
    </div>
  ) : (
    <Navigate to={'/login'} />
  );
}

export default PushSurvey;
