import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import TableContainer from '../../Table/GroupsTable';
import { Col, Container, Row, Label, Modal, ModalBody, ModalHeader, FormFeedback, Button, UncontrolledTooltip, Input } from 'reactstrap';
import { message, Select } from 'antd';

//Import Breadcrumb
//import Breadcrumbs from '../../../components/Common/Breadcrumb';
//import CustomModal from '../../../reusables/CustomModal';
import api from '../../api/index';
//import { Checkbox, DatePicker } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

// Formik validation
//import * as Yup from 'yup';
import { useFormik } from 'formik';
const ratingName = {
  min: 'min',
  max: 'max',
  det_min: 'detractors.min_threshold',
  det_max: 'detractors.max_threshold',
  pas_min: 'passives.min_threshold',
  pas_max: 'passives.max_threshold',
  pro_min: 'promoters.min_threshold',
  pro_max: 'promoters.max_threshold'
};

function SurveyQuestions() {
  const [questionList, setQuestionList] = useState([]);
  const [question, setQuestion] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [rating, setRating] = useState({
    min: '',
    max: '',
    det_min: '',
    det_max: '',
    pas_min: '',
    pas_max: '',
    pro_min: '',
    pro_max: ''
  });
  const [combo, setCombo] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (user?.login_counter === 0) {
      navigate('/edit-profile');
    }
  }, []);

  useEffect(() => {
    getAdminList(1, 50);
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    if (combo.length > 0) {
      getQuestionList(combo);
    }
  }, [combo]);
  const resetAll = () => {
    setOpen(false);
    setQuestion(null);
    QuestionValidation.resetForm();
  };

  const customNextPage = (page) => {
    if (page < totalPage) {
      getAdminList(page + 1, pageSize);
    } else {
      message.info('You are already on the last page');
    }
  };

  const customPrevPage = (page) => {
    if (page !== 1) {
      getAdminList(page - 1, pageSize);
    }
  };

  const customSetPageSize = (size) => {
    setPageSize(size);
  };
  const getAdminList = async (page = 1, pageSize = 10) => {
    try {
      const data = await api.survey.getAllSurvey(page, pageSize);
      if (data.status === 200) {
        setServiceList(data?.data?.data?.surveys);

        message.success('List refreshed');
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  const handleRating = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setRating({ ...rating, [name]: [value] });
  };
  const getQuestionList = async (services) => {
    try {
      const data = await api.question.getQuestions(services);
      if (data.status === 200) {
        setQuestionList(data?.data?.data?.questions);
        message.success('Questions fetched successfully');
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const updateQuestionList = async (body) => {
    try {
      const data = await api.question.editQuestion(body);
      if (data.status === 200) {
        combo?.length > 0 ? getQuestionList(combo) : null;
        resetAll();
        message.success('List updated successfully');
      } else {
        message.error(data.response.data.message);
        //setOpen(false)
      }
    } catch (error) {
      message.error(error.response.data.message);
      //setOpen(false)
    }
  };

  const createQuestion = async (body) => {
    try {
      const data = await api.question.createQuestion(body);
      if (data.status === 201) {
        combo?.length > 0 ? getQuestionList(combo) : null;
        resetAll();

        message.success('Question created successfully');
      }
      return data.data;
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const deleteQuestion = async (id) => {
    try {
      const data = await api.question.deleteQuestion(id);
      if (data.status == 200) {
        combo?.length > 0 ? getQuestionList(combo) : null;
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  const freeColumns = [
    {
      Header: 'Question',
      accessor: 'text',
      filterable: true
    },
    {
      Header: 'Type',
      accessor: 'type',
      filterable: true
    },
    {
      Header: 'Service Name',
      accessor: 'service_name',
      filterable: true
    },

    {
      Header: 'Created By',
      accessor: 'created_by',
      filterable: true
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      filterable: true,
      Cell: (data) => new Date(data?.value).toLocaleString()
    },
    {
      Header: 'Actions',
      Cell: (cellProps) => {
        return user.role === 'admin' ? (
          <div className="d-flex gap-3">
            {
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  //
                  const data = cellProps.row.original;
                  setOpen(true);
                  setQuestion(data);
                }}
              >
                <EditFilled className="font-size-12" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            }
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const data = cellProps.row.original;

                deleteQuestion(data?.id);
              }}
            >
              <DeleteFilled className="font-size-12" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        ) : null;
      }
    }
  ];
  const QuestionValidation = useFormik({
    enableReinitialize: true,
    initialValues:
      question?.type == 'rating_scale'
        ? {
            text: question?.text || '',
            type: question?.type || '',
            service_name: question?.service_name || '',
            created_by: question?.created_by || user?.email
          }
        : question?.type == 'open_ended'
        ? {
            text: question?.text || '',
            type: question?.type || '',
            service_name: question?.service_name || '',
            created_by: user?.email,
            placeholder: question?.open_ended_metadata.placeholder || '',
            min_character_limit: question?.open_ended_metadata.min_character_limit || '',
            max_character_limit: question?.open_ended_metadata.max_character_limit || ''
          }
        : {
            text: question?.text || '',
            type: question?.type || '',
            service_name: question?.service_name || '',
            created_by: user?.email,
            close_ended_metadata: {
              kind: question?.close_ended_metadata?.kind || '',
              text_options: question?.close_ended_metadata?.text_options || []
            }
          },

    onSubmit: (values) => {
      if (values.type === 'rating_scale') {
        values.id = question?.id;
        values.rating_scale_metadata = {
          min: 1,
          max: 10,
          detractors: {
            min_threshold: 1,
            max_threshold: 6
          },
          passives: {
            min_threshold: 7,
            max_threshold: 8
          },
          promoters: {
            min_threshold: 9,
            max_threshold: 10
          }
        };
      } else if (values.type === 'open_ended') {
        values.open_ended_metadata = {
          placeholder: values.placeholder,
          min_character_limit: values.min_character_limit,
          max_character_limit: values.max_character_limit
        };
        delete values.min_character_limit, delete values.max_character_limit;
      }

      console.log(values);
      //   delete values.email
      // }
      question ? updateQuestionList({ id: question.id, ...values }) : createQuestion(values);
    }
  });

  const handleQuestionChange = (value) => {
    setCombo(value);
  };
  return user ? (
    <div className="page-content">
      <Container fluid>
        <Modal centered style={{ marginTop: '50px' }} csize="md" isOpen={open}>
          <ModalHeader
            toggle={() => {
              resetAll();
              setOpen(false);
            }}
            tag="h4"
          >
            {!question ? 'Create' : 'Edit'} Question
          </ModalHeader>
          <ModalBody>
            <div>
              <div>
                {/* <h6 style={{ marginTop: '20px' }}>Agents</h6> */}
                <Row>
                  <Row span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Question</b>
                      </Label>
                      <Input
                        type="text"
                        name="text"
                        id="text"
                        className="form-control"
                        onChange={QuestionValidation.handleChange}
                        onBlur={QuestionValidation.handleBlur}
                        value={QuestionValidation.values.text || ''}
                      ></Input>
                      {QuestionValidation.touched.text && QuestionValidation.errors.text ? (
                        <FormFeedback type="invalid">{QuestionValidation.errors.text}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Service name</b>
                      </Label>
                      <Input
                        type="select"
                        multiline="true"
                        name="service_name"
                        id="service_name"
                        className="form-control"
                        onChange={QuestionValidation.handleChange}
                        onBlur={QuestionValidation.handleBlur}
                        value={QuestionValidation.values.service_name || ''}
                      >
                        <option value="">Select one...</option>
                        {serviceList?.map((val) => {
                          return (
                            <option key={val.service_name} value={val.service_name}>
                              {val.service_name}
                            </option>
                          );
                        })}
                      </Input>
                      {QuestionValidation.touched.service_name && QuestionValidation.errors.service_name ? (
                        <FormFeedback type="invalid">{QuestionValidation.errors.service_name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  {
                    <Col span={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">
                          <b>Question Type</b>
                        </Label>
                        <Input
                          type="select"
                          name="type"
                          id="type"
                          className="form-control"
                          onChange={QuestionValidation.handleChange}
                          onBlur={QuestionValidation.handleBlur}
                          value={QuestionValidation.values.type || ''}
                        >
                          <option value="">Select one...</option>
                          {['rating_scale', 'open_ended', 'close_ended'].map((val) => {
                            return (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            );
                          })}
                        </Input>
                        {QuestionValidation.touched.description && QuestionValidation.errors.description ? (
                          <FormFeedback type="invalid">{QuestionValidation.errors.description}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  }
                </Row>
                {/* {QuestionValidation.values.type == 'rating_scale' && (
                  <Row>
                    {['min', 'max', 'det_min', 'det_max', 'pas_min', 'pas_max', 'pro_min', 'pro_max'].map((r) => {
                      return (
                        <Col key={r} span={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">
                              <b>{r == 'min' || r == 'max' ? `${ratingName[r]}imum` : ratingName[r]}</b>
                            </Label>
                            <Input
                              type="text"
                              name={r}
                              id={r}
                              className="form-control"
                              onChange={QuestionValidation.handleChange}
                              onBlur={QuestionValidation.handleBlur}
                              value={QuestionValidation.values[r] || ''}
                            ></Input>
                            {QuestionValidation.touched[r] && QuestionValidation.errors[r] ? (
                              <FormFeedback type="invalid">{QuestionValidation.errors[r]}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                )} */}

                {QuestionValidation.values.type == 'open_ended' && (
                  <Row>
                    {['min_character_limit', 'max_character_limit', 'placeholder'].map((r) => {
                      return (
                        <Col key={r} span={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">
                              <b>{[r.replaceAll("_", " ")]}</b>
                            </Label>
                            <Input
                              type="text"
                              name={r}
                              id={r}
                              className="form-control"
                              onChange={QuestionValidation.handleChange}
                              onBlur={QuestionValidation.handleBlur}
                              value={QuestionValidation.values[r] || ''}
                            ></Input>
                            {QuestionValidation.touched[r] && QuestionValidation.errors[r] ? (
                              <FormFeedback type="invalid">{QuestionValidation.errors[r]}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                )}

                {QuestionValidation.values.type == 'close_ended' && (
                  <Row>
                    {['kind', 'text_options'].map((r) => {
                      return r == 'text_options' ? (
                        <Col key={r} span={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">
                              <b>{`${r.replace('_', ' ')}`}</b>
                            </Label>

                            <Select
                              style={{
                                width: '95%'
                              }}
                              name="close_ended_metadata"
                              id="close_ended_metadata"
                              mode="tags"
                              value={QuestionValidation?.values?.close_ended_metadata?.text_options}
                              placeholder="Add A text option"
                              onChange={(value) => {
                                const old = QuestionValidation?.values.close_ended_metadata;
                                QuestionValidation.setFieldValue('close_ended_metadata', {
                                  ...old,
                                  [r]: value
                                });
                              }}
                            />

                            {QuestionValidation.touched[r] && QuestionValidation.errors[r] ? (
                              <FormFeedback type="invalid">{QuestionValidation.errors[r]}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      ) : (
                        <Col key={r}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">
                              <b>Mode</b>
                            </Label>
                            <Input
                              type="select"
                              multiline="true"
                              name="close_ended_metadata"
                              id="close_ended_metadata"
                              className="form-control"
                              onChange={(e) => {
                                const old = QuestionValidation.values.close_ended_metadata;
                                console.log(old);
                                QuestionValidation.setFieldValue('close_ended_metadata', {
                                  ...old,
                                  [r]: e.target.value
                                });
                              }}
                              onBlur={QuestionValidation.handleBlur}
                              value={QuestionValidation.values?.close_ended_metadata?.kind}
                            >
                              <option value="">Select one...</option>
                              {['Radio', 'Checkbox'].map((val) => {
                                return (
                                  <option key={val} value={val}>
                                    {val}
                                  </option>
                                );
                              })}
                            </Input>
                            {QuestionValidation.touched.service_name && QuestionValidation.errors.service_name ? (
                              <FormFeedback type="invalid">{QuestionValidation.errors.service_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                )}
                <Row>
                  <Col span={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">
                        <b>Created By</b>
                      </Label>
                      <Input
                        type="text"
                        name="created_by"
                        id="created_by"
                        placeholder="created_by"
                        className="form-control"
                        onBlur={QuestionValidation.handleBlur}
                        value={user?.email}
                      ></Input>
                      {QuestionValidation.touched.created_by && QuestionValidation.errors.created_by ? (
                        <FormFeedback type="invalid">{QuestionValidation.errors.created_by}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <Button
                    type="button"
                    disabled={user.role === 'agent'}
                    onClick={() => {
                      QuestionValidation.handleSubmit();
                    }}
                    className="btn btn-success "
                    // disabled={editBingoProfile}
                  >
                    {question ? 'Edit' : 'Add'}
                  </Button>
                  <button
                    type="button"
                    onClick={() => {
                      resetAll();
                      setOpen(false);
                    }}
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <TableContainer
          columns={freeColumns}
          data={questionList}
          handleQuestionChange={handleQuestionChange}
          setShowDetailsModal={() => {}}
          isGlobalFilter={true}
          free={true}
          isAddUserList={false}
          isQuestionList={true}
          controlledPageIndex={page}
          customPageCount={totalPage}
          customSetPageSize={(number) => customSetPageSize(number)}
          customNextPage={() => customNextPage(page)}
          customCanNextPage={page < totalPage}
          customCanPrevPage={page !== 1}
          customPageSize={pageSize}
          customPrevPage={() => customPrevPage(page)}
          optionList={serviceList?.map((s) => {
            return { value: s.service_name, label: s.service_name };
          })}
          handleUserClick={() => {}}
          handleWhiteList={() => {}}
          handleAddWhiteList={() => {
            setOpen(true);
          }}
          className="custom-header-css"
        />
      </Container>
    </div>
  ) : (
    <Navigate to={'/login'} />
  );
}

export default SurveyQuestions;
