import axios from 'axios';
// import store from "../redux/configureStore";
import Admin from './admin';
import Messages from './messages';
import Payments from './payments';
import Analytics from './analytics';
import Survey from './survery';
import Question from './questions';
import Push from './push';
import Response from './response';
import Settings from './nps'
import Report from './reports'

export const checkValidTime = (date) => {
  // Create a new Date object for the given date

  if (!date) return;

  const givenDate = new Date(date * 1000);

  // Get the current date
  const currentDate = new Date();

  // Compare the two dates using getTime() method and check if the given date is in the past
  if (givenDate.getTime() < currentDate.getTime()) {
    return false;
  } else {
    return true;
  }
};

const baseUrl = process.env.REACT_APP_BASE_URL;



const surveyUrl = process.env.REACT_APP_SURVEY_URL;





//"https://tnps-staging.ewsc.co.sz/api/v1/"

//process.env.REACT_APP_SURVEY_URL;


// ("https://5zljr2hxw9.execute-api.eu-west-2.amazonaws.com");

const coreAxiosInstance = axios.create({
  baseURL: `${baseUrl}`
});

const surveyInstance = axios.create({
  baseURL: `${surveyUrl}`
});

coreAxiosInstance.interceptors.request.use(async (request) => {
  const expired = localStorage.getItem('expired') ? JSON.parse(localStorage.getItem('expired')) : null;
  const token = localStorage.getItem('eswc_token');
  if (token) {
    request.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
  }

  if (request.url !== '/agent/login' && (checkValidTime(expired) == false || expired == null)) {
  
    localStorage.setItem('user', null);
    localStorage.setItem('eswc_token', null);
    localStorage.setItem('expired', null);
  }
  return request;
});

surveyInstance.interceptors.request.use(async (request) => {
  const expired = localStorage.getItem('expired') ? JSON.parse(localStorage.getItem('expired')) : null;
  const token = localStorage.getItem('eswc_token');
  if (token) {
    request.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
  }

  if (request.url !== '/agent/login' && (checkValidTime(expired) == false || expired == null)) {
   
    localStorage.setItem('user', null);
    localStorage.setItem('eswc_token', null);
    localStorage.setItem('expired', null);
  }
  return request;
});
coreAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 
    if (error.response.data.message == 'Unauthorized - access_token override') {
     
      localStorage.setItem('user', null);
      localStorage.setItem('eswc_token', null);
      localStorage.setItem('expired', null);
    }
    return error;
  }
);

export default {
  admin: new Admin(coreAxiosInstance),
  messages: new Messages(coreAxiosInstance),
  payments: new Payments(coreAxiosInstance),
  analytics: new Analytics(coreAxiosInstance),
  survey: new Survey(surveyInstance),
  question: new Question(surveyInstance),
  push: new Push(surveyInstance),
  response: new Response(surveyInstance),
  settings: new Settings(coreAxiosInstance),
  reports: new Report(coreAxiosInstance),
};
