export default class Report {
  constructor(client) {
    this.client = client;
  }

  getAllReport(page = 1, pageSize = 10, status = "open") {
    return this.client.get(`/report?page=${page}&limit=${pageSize}&status=${status}`);
  }

  editReport(body) {
    const id = body.id;
    delete body.id;
    return this.client.put(`/report/${id}`, body);
  }
}