import { useRoutes } from 'react-router-dom';
import { lazy, useState, useEffect } from 'react';
import { message, Modal, Button } from 'antd';
import useWebSocket from 'react-use-websocket';
import api, { checkValidTime } from 'api/index';
import messageSound from 'assets/sounds/notification.mp3';
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'components/Loadable';

// Lazy-loaded components
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Reports = Loadable(lazy(() => import('pages/components-overview/Reports')));
const AllMessages = Loadable(lazy(() => import('pages/components-overview/AllMessages')));
const MyMessages = Loadable(lazy(() => import('pages/components-overview/MyMessages')));
const Settings = Loadable(lazy(() => import('pages/components-overview/Settings')));
const NpsSettings = Loadable(lazy(() => import('pages/components-overview/NpsSettings')));
const Payments = Loadable(lazy(() => import('pages/components-overview/Payments')));
const CreateSurvey = Loadable(lazy(() => import('pages/components-overview/CreateSurvey')));
const SurveyQuestions = Loadable(lazy(() => import('pages/components-overview/SurveyQuestions')));
const PushSurvey = Loadable(lazy(() => import('pages/components-overview/PushQuestions')));
const Responses = Loadable(lazy(() => import('pages/components-overview/Responses')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

const token = JSON.parse(localStorage.getItem('eswc_token'));
const expired = localStorage.getItem('expired') ? JSON.parse(localStorage.getItem('expired')) : null;
const socketUrl = `wss://cb-backend.ewsc.co.sz/agentws/?token=${token}`;

export default function ThemeRoutes() {
  const [reports, setReports] = useState([]);
  const [lastFetch, setLastFetch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userInteracted, setUserInteracted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
    shouldReconnect: (_closeEvent) => {
      if (!token || checkValidTime(expired) === false || expired == null) {
        return false;
      } else {
        return true;
      }
    },
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) => Math.min(Math.pow(1.5, attemptNumber) * 1000, 10000),
    onMessage: (event) => {
      return;
    }
  });

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
      
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };

    if (!userInteracted) {
     
      document.addEventListener('click', handleUserInteraction);
      document.addEventListener('keydown', handleUserInteraction);
    }
  }, [userInteracted]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.reports.getAllReport(currentPage, 10, 'open');
        const newReports = response.data.data.reports;
        const { totalPages } = response.data.data;
        if (JSON.stringify(newReports) !== JSON.stringify(reports)) {
          const sound = new Audio(messageSound);
          setReports(newReports);
          setTotalPages(totalPages);
          if (location.pathname !== '/reports') {
          if (userInteracted && lastFetch) {
            // If the user has interacted, use message API and play sound
            message.open({
              type: 'info',
              content: 'New reports available, check reports page for more details',
              duration: 2,
              
            });
            sound.play()
          } else if(lastFetch){
            // If the user has not interacted, show a modal
            setShowModal(true);
          }

          setLastFetch(new Date());
        }
      }
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchReports();
    const interval = setInterval(fetchReports, 5000);
    return () => clearInterval(interval);
  }, [reports, lastFetch, currentPage, userInteracted]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      {useRoutes([
        {
          path: '/',
          element: <MainLayout />,
          children: [
            { path: '/', element: <DashboardDefault /> },
            { path: 'all-messages', element: <AllMessages /> },
            {
              path: 'reports',
              element: <Reports reports={reports} totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
            },
            { path: 'settings', element: <Settings /> },
            { path: 'nps-settings', element: <NpsSettings /> },
            { path: 'my-messages', element: <MyMessages lastMessage={lastMessage} sendMessage={sendMessage} /> },
            { path: 'payments', element: <Payments /> },
            { path: 'survey-questions', element: <SurveyQuestions /> },
            { path: 'create-survey', element: <CreateSurvey /> },
            { path: 'push-survey', element: <PushSurvey /> },
            { path: 'survey-RESPONSE', element: <Responses /> }
          ]
        },
        {
          path: '/',
          element: <MinimalLayout />,
          children: [
            { path: 'login', element: <AuthLogin /> },
            { path: 'edit-profile', element: <AuthRegister /> }
          ]
        }
      ])}

      {/* Modal for new reports */}
      <Modal
        title="New Reports"
        visible={showModal}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>
        ]}
      >
        <p>New reports have arrived. Please check the reports page for more details.</p>
      </Modal>
    </>
  );
}


