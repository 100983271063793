import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import TableContainer from '../../Table/GroupsTable';
import MonthlyBarChart from '../dashboard/PieChart';
import MainCard from 'components/MainCard';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Col, Container, Row, Label, Modal, ModalBody, ModalHeader, Button,  Input } from 'reactstrap';
import { message } from 'antd';
import { Select } from 'antd';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

import api from '../../api/index';

const slabel = ['Detractors', 'Passives', 'Promoters'];
const nlabel = ['Email', 'Sms', 'Whatsapp', "Widget"];

function Responses() {
  const [questionList, setQuestionList] = useState([]);
  const [type, setType] = useState('id');
  const [question, setQuestion] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [startDate, setStartDate] = useState(moment(new Date(Date.now()).getTime() - 7 * 24 * 60 * 60 * 1000).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
  const [statistics, setStatistics] = useState({ series: [], numbers: [], total: 0 });

  //

  // const [rating, setRating] = useState({
  //   min: '',
  //   max: '',
  //   det_min: '',
  //   det_max: '',
  //   pas_min: '',
  //   pas_max: '',
  //   pro_min: '',
  //   pro_max: ''
  // });

  const [combo, setCombo] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (user?.login_counter === 0) {
      navigate('/edit-profile');
    }
  }, []);

  useEffect(() => {
    getAdminList(1, 50);
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    if (combo.length > 0 && !open) {
      getResponseList(combo);
    }
  }, [combo]);

  useEffect(() => {
    if (endDate && startDate) {
      filterByDate({
        start_date: startDate,
        end_date: endDate,
        service_name:
          combo.length > 0
            ? combo
            : serviceList?.map((s) => {
                return { value: s.service_name, label: s.service_name };
              }),
        show_list: true
      });
    }
  }, [endDate, startDate]);
  const resetAll = () => {
    setOpen(false);
    setQuestion(null);
    //QuestionValidation.resetForm();
  };

  const filterByDate = async (body) => {
    try {
      const stats = [];
      const nums = [];
      const data = await api.response.filterResponses(body);

      //(stats[0] = data?.data.data.totalResponses),
      (stats[0] = data?.data?.data?.detractorsPercentage||0),
        (stats[1] = data?.data?.data?.passivesPercentage||0),
        (stats[2] = data?.data?.data?.promotersPercentage||0),
        (nums[0] = data?.data?.data?.emailCount),
        (nums[1] = data?.data?.data?.smsCount),
        (nums[2] = data?.data?.data?.whatsappCount),
        (nums[3] = data?.data?.data?.widgetCount);

      setStatistics({ series: stats, numbers: nums, total: data?.data?.data?.totalResponses });
      setTotalPage(data?.data?.data?.to);
      setQuestionList(data?.data?.data?.records);
      setOpen(false);
    } catch (error) {}
  };
  const getAdminList = async (page = 1, pageSize = 10) => {
    try {
      const data = await api.survey.getAllSurvey(page, pageSize);
      if (data?.status === 200) {
        setServiceList(data?.data?.data?.surveys);

        message.success('List refreshed');
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getResponseList = async (services) => {
    try {
      const data = await api.response.getResponses(services);
      if (data?.status === 200) {
        setQuestionList(data?.data?.data?.data);
        message.success('Responses fetched successfully');
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleDateChange = (val) => {
    const [startMoment, endMoment] = val;

    if (!startMoment || !endMoment) {
      return;
    }

    const start = moment(startMoment.toLocaleString()).format('YYYY-MM-DD');

    const end = moment(endMoment.toLocaleString()).format('YYYY-MM-DD');

    setStartDate(start);
    setEndDate(end);
  };

  const freeColumns = [
    {
      Header: 'Question id',
      accessor: 'id',
      filterable: true
    },

    {
      Header: 'Response',
      accessor: 'response',
      filterable: true
    },

    {
      Header: 'Answered By',
      accessor: 'answered_by',
      filterable: true
    },
    {
      Header: 'Result',
      accessor: 'result',
      filterable: true
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      filterable: true,
      Cell: (data) => new Date(data?.value).toLocaleString()
    }
  ];

  const handleQuestionChange = (value) => {
    setCombo(value);
  };

  return user ? (
    <div className="page-content">
      <Container fluid>
        <Modal scrollable style={{ marginTop: '80px' }} centered size="md" isOpen={open}>
          <ModalHeader
            toggle={() => {
              resetAll();
              setOpen(false);
            }}
            tag="h4"
          >
            Advanced Filter
          </ModalHeader>
          <ModalBody>
            <Row span={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputState">
                  <b>Select service(s)</b>
                </Label>
                <Select
                  mode="tags"
                  style={{ width: '80%' }}
                  placeholder="Pick a service"
                  onChange={handleQuestionChange}
                  options={serviceList?.map((s) => {
                    return { value: s.service_name, label: s.service_name };
                  })}
                />
              </div>
            </Row>
            <Row span={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputState">
                  <b>Pick start and end dates</b>
                </Label>
                <RangePicker
                  format="YYYY-MM-DD"
                  style={{ width: '80%' }}
                  // value={[moment(startDate), moment(endDate)]}
                  onChange={handleDateChange}
                />
              </div>
            </Row>
          </ModalBody>
        </Modal>

        <TableContainer
          columns={freeColumns}
          data={questionList}
          startDate={startDate}
          endDate={endDate}
          handleQuestionChange={handleQuestionChange}
          setShowDetailsModal={() => {}}
          isGlobalFilter={true}
          free={true}
          handleDateChange={handleDateChange}
          isAddUserList={false}
          isResponseList={true}
          customPageSize={pageSize}
          optionList={serviceList?.map((s) => {
            return { value: s.service_name, label: s.service_name };
          })}
          handleUserClick={() => {}}
          handleWhiteList={() => {}}
          handleAddWhiteList={() => {
            setOpen(true);
          }}
          className="custom-header-css"
        />

        {statistics.total > 0 && (
          <Grid style={{ marginTop: '50px' }} container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={6} md={6} lg={6}>
              <MainCard sx={{ mt: 2 }} content={false}>
                <Box sx={{ p: 3, pb: 0 }}>
                  <Stack spacing={2}>
                    <Typography style={{}} variant="h6">
                      {' '}
                      <b>NPS SCORE: &nbsp; {(statistics.series[2] - statistics.series[0]).toFixed(3)}</b>
                    </Typography>

                    <Typography variant="h6" color="textSecondary">
                      Total: 100%
                    </Typography>
                  </Stack>
                </Box>
                <MonthlyBarChart labels={slabel} colors={['#FF0000', '#0000FF', '#50C878']} series={statistics.series} />
              </MainCard>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item />
              </Grid>
              <MainCard sx={{ mt: 2 }} content={false}>
                <Box sx={{ p: 3, pb: 0 }}>
                  <Stack spacing={2}>
                    <Typography variant="h6" color="textSecondary">
                      Total: {statistics.total}
                    </Typography>
                  </Stack>
                </Box>
                <MonthlyBarChart labels={nlabel} series={statistics.numbers} />
              </MainCard>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  ) : (
    <Navigate to={'/login'} />
  );
}

export default Responses;
